/* JS */

/* react */
import React from 'react';
/* third part components */

/* custom components */
import CaseStudyPage from './CaseStudyPage'; 

var gsap;
var detect = require('is-client');
if (detect()) {
	gsap = require('gsap');
	let sctr = require('gsap/ScrollTrigger');
	gsap.gsap.registerPlugin(sctr);
}

const ApparentePage = props => {
	return (
		<CaseStudyPage link="apparente" title="Branding, Design e Progettazione Mobile - Apparente"
		relPath="apparente" description="Tra proprietari e inquilini, tutto in un'app.">
		</CaseStudyPage>
	);
};

export default ApparentePage;
